


























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import { useToast } from 'vue-toastification/composition'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import RelationSection from '@/components/relations/RelationSection.vue'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'

export default defineComponent({
    name: 'Relations',
    components: { RelationSection },

    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const threats = ref([])
        const vulnerabilities = ref([])
        const risks = ref([])
        const risksWithControls = ref([])
        const controls = ref([])
        const controlsWithRisks = ref([])
        const toast = useToast()
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const loading = ref(false)

        const getAllRisks = async () => {
            try {
                loading.value = true;
                const query = `
                query{
                    risks{
                        items{
                            id
                            order
                            label: description
                            fullLabel: fullDescription,
                            created_by{
                                id
                                username
                            }
                        }
                    }
                }
                `

                risks.value = []
                const result = await apolloClient.getGraphqlData(query)
                if (result) {
                    // Copy array to be able to sort it in place
                    const risksCopy = [...result.data.risks.items]
                    // sort by id (ascending)
                    risksCopy.sort((a, b) => (a.order > b.order ? 1 : -1))
                    const removedUserCreatedEntities = risksCopy.filter(
                        (val) => val['created_by'].username === variables.DEFAULT_USERNAME
                    )
                    risks.value = removedUserCreatedEntities
                }
            } catch (err) {
                throw Error(`Error while retrieving risks: ${err}`)
            }
            finally {
                loading.value = false
            }
        }

        const getThreatsWithRisks = async () => {
            try {
                loading.value = true
                const query = `
                query{
                    threats {
                        items{
                            id
                            order
                            label: description
                            fullLabel: fullDescription
                            created_by{
                                id
                                username
                            }
                        }
                    }
                }
                `

                threats.value = []
                const result = await apolloClient.getGraphqlData(query)
                if (result) {
                    // Copy array to be able to sort it in place
                    const threatsCopy = [...result.data.threats.items]
                    // sort by id (ascending)
                    threatsCopy.sort((a, b) => (a.order > b.order ? 1 : -1))
                    // remove user created entities by checking for default values of username
                    const removedUserCreatedEntities = threatsCopy.filter(
                        (val) => val['created_by'].username === variables.DEFAULT_USERNAME
                    )
                    threats.value = removedUserCreatedEntities
                }
            } catch (err) {
                throw Error(`Error while retrieving threats: ${err}`)
            } finally {
                loading.value = false
            }
        }

        const saveThreatRisks = async (threat, risks) => {
            const riskIDs = risks.map((risk) => risk.id)
            const query = `mutation ($input: UpdateMasterThreatInput!) {
                updateMasterThreat(id: ${threat.id}, input: $input) {
                    threat {
                        id
                    }
                    status
                }
              }`
            const input = {
                risks: riskIDs,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(query, input)
            } catch (err) {
                toast.success(t('RELATIONS_UPDATE_FAIL', language.value))
                throw Error('Error while updating master threat')
            }
            if (result) {
                toast.success(t('RELATIONS_UPDATE_SUCCESS', language.value))
                // get the updated list
                getThreatsWithRisks()
            }
        }

        const getVulnerabilitiesWithRisks = async () => {
            try {
                loading.value = true
                const query = `
                query{
                    vulnerabilities {
                       items{
                            id
                            order
                            label: description
                            fullLabel: fullDescription
                            created_by{
                                id
                                username
                            }
                       }
                    } 
                }
                `

                vulnerabilities.value = []
                const result = await apolloClient.getGraphqlData(query)
                if (result) {
                    // Copy array to be able to sort it in place
                    const vulnerabilitiesCopy = [...result.data.vulnerabilities.items]
                    // sort by id (ascending)
                    vulnerabilitiesCopy.sort((a, b) => (a.order > b.order ? 1 : -1))
                    // remove user created entities by checking for default values of username
                    const removedUserCreatedEntities = vulnerabilitiesCopy.filter(
                        (val) => val['created_by'].username === variables.DEFAULT_USERNAME
                    )
                    vulnerabilities.value = removedUserCreatedEntities
                }
            } catch (err) {
                throw Error(`Error while retrieving vulnerabilities: ${err}`)
            } finally {
                loading.value = false
            }
        }

        const saveVulnerabilityRisks = async (vulnerability, risks) => {
            const riskIDs = risks.map((risk) => risk.id)
            const query = `mutation ($input: UpdateMasterVulnerabilityInput!) {
                updateMasterVulnerability(id: ${vulnerability.id}, input: $input) {
                    vulnerability {
                        id
                    }
                    status
                }
              }`
            const input = {
                risks: riskIDs,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(query, input)
            } catch (err) {
                toast.success(t('RELATIONS_UPDATE_FAIL', language.value))
                throw Error('Error while updating master vulnerabilities')
            }
            if (result) {
                toast.success(t('RELATIONS_UPDATE_SUCCESS', language.value))
                // get updated list
                getVulnerabilitiesWithRisks()
            }
        }

        const getControlsWithRisks = async () => {
            try {
                const query = `
                query{
                    controls{
                    items{
                        id
                        order
                        label: controlNumber 
                        fullLabel: description
                        prefix: originNorm {
                            name
                        }
                        created_by{
                            id
                            username
                        }
                    }
                  }
                }
                `

                controlsWithRisks.value = []
                const result = await apolloClient.getGraphqlData(query)
                if (result) {
                    // Copy array to be able to sort it in place
                    const controlsCopy = [...result.data.controls.items]
                    // remove user created entities by checking for default values of username
                    const removedUserCreatedEntities = controlsCopy.filter(
                        (val) => val['created_by'].username === variables.DEFAULT_USERNAME
                    )
                    // sort by id (ascending)
                    controlsWithRisks.value = removedUserCreatedEntities.sort((a, b) =>
                        a.order > b.order ? 1 : -1
                    )
                }
            } catch (err) {
                throw Error(`Error while retrieving controls: ${err}`)
            } finally {
                loading.value = false
            }
        }

        const saveControlRisks = async (control, risks) => {
            const riskIDs = risks.map((risk) => risk.id)
            const query = `mutation ($input: UpdateMasterControlInput!) {
                updateMasterControl(id: ${control.id}, input: $input) {
                    control {
                        id
                    }
                    status
                }
              }`
            const input = {
                risks: riskIDs,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(query, input)
            } catch (err) {
                toast.success(t('RELATIONS_UPDATE_FAIL', language.value))
                throw Error('Error while updating master control')
            }
            if (result) {
                toast.success(t('RELATIONS_UPDATE_SUCCESS', language.value))
                // get updated control list
                getControlsWithRisks()
            }
        }

        onMounted(() => {
            getAllRisks()
            getThreatsWithRisks()
            getVulnerabilitiesWithRisks()
            getControlsWithRisks()
        })

        return {
            loading,
            controls,
            language,
            risks,
            controlsWithRisks,
            risksWithControls,
            saveControlRisks,
            saveThreatRisks,
            saveVulnerabilityRisks,
            t,
            threats,
            vulnerabilities,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
        }
    },
})
